import './App.css';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Step from './components/Step';
import BottomLead from './components/BottomLead';
import Footer from './components/Footer';

import logo from './assets/logo.png';
import signup from './assets/signup.png';
import events from './assets/events.png';
import voteevent from './assets/voteevent.png';

function App() {
  const data = {
    hero: {
      appType: 'Ntopor Social',
      tagLine: 'Join our ever vibrant community and enjoy premium "Ntopor"',
      description: 'Download the Ntopor mobile app now on',
      mainActionText: 'Playstore',
      extraActionText: 'App Store',
    },
    step1: {
      title: 'Create an account',
      heading: 'Create/login to an existing account to get started',
      description:
        'create and account with your email, phone and password, or sign in with your google account. to get started with our app is simple and straight to the point, jump on and enjoy the majic of Ntopor',
      img: signup,
      alternate: false,
    },
    step2: {
      title: 'Browse Events',
      heading: 'Browse all events and purchase Tickets to events',
      description:
        'Ntopor offers you the most recent events to our users. They can go throught the app, find the most exciting events and purchase tickets to them. we offer verified users the ability to create events and create ticket packages and offer to clients',
      img: events,
      alternate: true,
    },
    step3: {
      title: 'Browse Voting Charts',
      heading: 'Browse the trending charts and vote your Favorites',
      description:
        'On Ntopor you can browse the most recent charts and competitions hosted on our platform by us at Ntopor and our verified users. select your varorite participants in the most exciting charts and place your votes',
      img: voteevent,
      alternate: false,
    },
    bottomLead: {
      actionText: 'Download Ntopor now',
      description:
        'Available on your favourite store. No mobile device is left out.',
      mainActionText: 'Playstore',
      extraActionText: 'App Store',
    },
  };
  return (
    // __________________________TODO: ____________________
    // Add Montserrat font to everything (body)

    <div className="box-border">
      <div className="flex flex-col">
        <Navbar logo={logo} />
        <Hero
          appType={data.hero.appType}
          tagLine={data.hero.tagLine}
          description={data.hero.description}
          mainActionText={data.hero.mainActionText}
          extraActionText={data.hero.extraActionText}
        />

        <div
          id="divider"
          className="rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>

        <div
          id="faq"
          className="pt-10 lg:pt-20 mb-10 lg:mb-20 text-3xl lg:text-5xl font-semibold text-center text-blue-800 lg:font-bold">
          How the app works{' '}
        </div>

        <Step
          title={data.step1.title}
          heading={data.step1.heading}
          description={data.step1.description}
          img={data.step1.img}
          alternate={data.step1.alternate}
        />
        <Step
          title={data.step2.title}
          heading={data.step2.heading}
          description={data.step2.description}
          img={data.step2.img}
          alternate={data.step2.alternate}
        />
        <Step
          title={data.step3.title}
          heading={data.step3.heading}
          description={data.step3.description}
          img={data.step3.img}
          alternate={data.step3.alternate}
        />

        <BottomLead
          actionText={data.bottomLead.actionText}
          description={data.bottomLead.description}
          mainActionText={data.bottomLead.mainActionText}
          extraActionText={data.bottomLead.extraActionText}
        />

        <Footer logo={logo} />
      </div>
    </div>
  );
}

export default App;
