import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import f_3d2 from '../assets/3d2.png';

const Hero = ({
  appType,
  tagLine,
  description,
  mainActionText,
  extraActionText,
}) => {
  // Intersection observer hook
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust threshold as needed
  });

  // Animation variants
  const imageVariants = {
    hidden: { scale: 0, translateY: '100%' },
    visible: { scale: 1, translateY: '0%' },
  };

  return (
    <div id="product">
      <div
        style={{ textShadow: '1px 1px 1px cyan' }}
        className="flex flex-col items-center justify-start font-sans min-h-[30rem] bg-gray-50 pt-20 lg:pt-10 lg:pb-20 pb-10 lg:bg-hero bg-cover bg-hero_sm">
        <div>
          <p className="p-3 pt-12 text-lg font-bold text-orange-800 lg:text-orange-700">
            {appType}
          </p>
        </div>
        <div>
          <h1 className="p-2 text-3xl lg:text-5xl font-bold text-center text-white lg:text-blue-800 lg:mx-auto lg:w-3/4 lg:text-6xl lg:text-gray-100 font-fancy-font">
            {tagLine}
          </h1>
        </div>
        <div>
          <p className="p-4 pt-6 text-xl leading-8 text-center text-gray-500 lg:text-gray-200">
            {description}
          </p>
        </div>
        <div className="relative z-50 flex flex-col items-center justify-between h-48 lg:space-x-8 pt-7 lg:pt-0 lg:flex-row lg:justify-between lg:w-90">
          <a
            href="https://play.google.com/store/apps/details?id=com.batgroup.ntopor"
            target="_blank"
            className="pt-4 pb-4 pl-10 pr-10 text-xl font-semibold text-center text-white transition-all bg-orange-600 rounded-full shadow-2xl lg:ml-5 hover:bg-orange-700 focus:outline-none ring-4 ring-orange-600 lg:ring-2 lg:font-medium font-fancy-font"
            rel="noreferrer">
            {mainActionText}
          </a>
          <a
            href="https://apps.apple.com/app/ntopor/id6466199564"
            target="_blank"
            rel="noreferrer"
            className="pt-4 pb-4 pl-10 pr-10 text-xl font-semibold text-center text-orange-500 transition-all rounded-full shadow-2xl lg:mr-5 hover:text-orange-500 hover:bg-gray-50 bg-gray-50 focus:outline-none ring-4 ring-orange-500 lg:font-medium lg:text-gray-50 lg:bg-opacity-0 lg:ring-2 lg:ring-white font-fancy-font">
            {extraActionText}
          </a>
        </div>
      </div>
      <div className="z-0 flex flex-row items-start justify-center w-screen lg:h-[400px] h-[500px] pt-20 mb-16 bg-gray-50 lg:bg-white lg:mb-20 lg:w-full lg:h-96 lg:pt-20">
        <motion.img
          ref={ref}
          className="absolute left-0 lg:left-auto lg:-mt-40 lg:w-[40%] w-[100%]"
          src={f_3d2}
          alt=""
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={imageVariants}
          transition={{ duration: 1 }}
        />
      </div>
    </div>
  );
};

export default Hero;
