import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Step = ({ title, heading, description, img, alternate }) => {
  const row = 'lg:items-center lg:flex lg:flex-row lg:justify-center ';
  const rowReverse =
    'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center ';

  // Variants for animation
  const slideInVariants = {
    hidden: { x: alternate ? -300 : 300 },
    visible: { x: 0 },
  };

  const slideInVariants2 = {
    hidden: { x: alternate ? 300 : -300 },
    visible: { x: 0 },
  };

  // Intersection observer hook
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust threshold as needed
  });

  return (
    <div
      ref={ref}
      className={`${alternate ? row : rowReverse} lg:h-[600px] my-5`}>
      <motion.div
        className={`w-[100%] lg:w-[50%] lg:-ml-20 lg:-my-10 lg:flex lg:flex-col lg:justify-center ${
          alternate ? 'lg:items-end' : 'lg:items-start'
        } px-4 lg:px-20 h-[100%]`}
        variants={slideInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        transition={{ duration: 1, delay: 0.2 }}>
        <p
          className={`pb-2 lg:pb-5 text-2xl lg:text-3xl font-semibold text-center text-orange-400 lg:pb-0 lg:-mb-3  lg:font-bold  ${
            alternate ? 'lg:text-right' : 'lg:text-left'
          }`}>
          {title}
        </p>
        <p
          className={`pb-5 my-3 lg:my-5 text-3xl lg:text-4xl font-semibold leading-relaxed text-center text-blue-800 lg:pb-4 lg:text-3xl lg:pl-0 lg:font-bold ${
            alternate ? 'lg:text-right' : 'lg:text-left'
          }`}>
          {heading}
        </p>
        <p
          className={`w-[100%] pb-5 px-3  text-xl lg:text-xl leading-10 text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-lg lg:p-0 lg:pl-0 lg:pr-0 ${
            alternate ? 'lg:text-right' : 'lg:text-left'
          }`}>
          {description}
        </p>
      </motion.div>
      <motion.div className="lg:ml-0 w-[100%] lg:w-[48%] flex justify-center">
        <motion.img
          className="lg:-mt-24 lg:-mb-20 w-[60%] lg:w-[40%]"
          src={img}
          alt=""
          variants={slideInVariants2}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          transition={{ duration: 1, delay: 0.2 }}
        />
      </motion.div>
    </div>
  );
};

export default Step;
