import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import f_3d2 from '../assets/small_hero.png';

const BottomLead = ({
  actionText,
  description,
  mainActionText,
  extraActionText,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust threshold as needed
  });

  const imageAnimation = {
    hidden: { x: -100, scale: 0 },
    visible: { x: 0, scale: 1 },
  };

  return (
    <div className="flex flex-col justify-center h-screen bg-blue-800 lg:pt-24 lg:pb-14 lg:bg-cover lg:h-full lg:bg-action pt-6 relative ">
      <p className="p-5 text-4xl lg:text-5xl font-bold leading-normal text-center text-white lg:text-4xl lg:pt-10">
        {actionText}
      </p>
      <p className="pl-12 pr-12 text-xl leading-8 text-center text-gray-300 lg:text-gray-200 lg:font-medium">
        {description}
      </p>
      <div className="flex flex-col flex-wrap items-center justify-center w-full h-48 pt-5 lg:flex-row lg:pt-1">
        <a
          href="https://play.google.com/store/apps/details?id=com.batgroup.ntopor"
          target="_blank"
          className="pt-3 pb-3 pl-8 pr-8 mt-3 text-2xl font-semibold text-center text-white transition-all bg-orange-600 shadow-2xl lg:mt-0 rounded-full hover:bg-orange-100 hover:text-orange-600 focus:outline-none ring-2 ring-orange-600 "
          rel="noreferrer">
          {mainActionText}
        </a>
        <a
          href="https://apps.apple.com/app/ntopor/id6466199564"
          target="_blank"
          rel="noreferrer"
          className="pt-3 pb-3 pl-8 pr-8 mt-8 text-2xl font-semibold text-center text-white transition-all shadow-2xl lg:ml-5 lg:mt-0 rounded-full hover:text-orange-500 hover:bg-gray-50 focus:outline-none ring-2 ring-gray-300 ">
          {extraActionText}
        </a>
      </div>
      <p className="px-10 text-xl leading-8 text-center text-gray-300 lg:text-gray-200 lg:font-medium mt-20 lg:mt-5">
        write us at <a href="mailto:info@ntopor.com">info@ntopor.com</a> for
        more information
      </p>
      <motion.img
        ref={ref}
        className="absolute w-[20%]"
        src={f_3d2}
        alt="Your Image"
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={imageAnimation}
        transition={{ duration: 0.8 }}
      />
    </div>
  );
};

export default BottomLead;
